import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import { recommendationItemTypes } from '../../lib/constants';

import { commands } from '@uiw/react-md-editor';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

import X from '../../assets/iconography/x.svg';
import moment from 'moment';

export default function RecommendationItem() {
  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(false);
  const [params, setParams] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);

  const fields_by_type = {
    food: ['photo_upload', 'title', 'key_ingredients', 'description'],
    probiotic: [
      'photo_upload',
      'title',
      'description',
      'key_ingredients',
      'age_low',
      'age_high',
      'info',
      'link',
      'discount_code',
      'allergens_cows_milk',
      'allergens_gluten',
      'allergens_egg',
      'allergens_soy',
      'allergens_peanuts',
      'allergens_treenuts',
      'allergens_shellfish',
      'vegan',
      'vegetarian',
    ],
    product: [
      'photo_upload',
      'title',
      'description',
      'key_ingredients',
      'info',
      'link',
      'discount_code',
    ],
    recipe: [
      'photo_upload',
      'title',
      'description',
      'info',
      'discount_code',
      'time',
      'link',
    ],
    resource: [
      'photo_upload',
      'title',
      'description',
      'info',
      'discount_code',
      'link',
    ],
    supplement: [
      'photo_upload',
      'title',
      'description',
      'key_ingredients',
      'age_low',
      'age_high',
      'info',
      'link',
      'discount_code',
      'allergens_cows_milk',
      'allergens_gluten',
      'allergens_egg',
      'allergens_soy',
      'allergens_peanuts',
      'allergens_treenuts',
      'allergens_shellfish',
      'vegan',
      'vegetarian',
    ],
    tip: ['title', 'description'],
  };

  const getRecommendationItem = async () => {
    setFetching(true);
    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/recommendationItems/${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res.status === 200) {
      const { recommendationItem } = await res.json();

      setParams(recommendationItem);
      setFetching(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }
  };

  const updateRecommendationItem = async () => {
    setErrors([]);
    setUpdated(false);

    const updateData = {
      type: params.type,
      published: params.published,
      age_low: params.age_low,
      age_high: params.age_high,
      allergens_cows_milk: params.allergens_cows_milk,
      allergens_gluten: params.allergens_gluten,
      allergens_egg: params.allergens_egg,
      allergens_soy: params.allergens_soy,
      allergens_peanuts: params.allergens_peanuts,
      allergens_treenuts: params.allergens_treenuts,
      allergens_shellfish: params.allergens_shellfish,
      description: params.description,
      discount_code: params.discount_code,
      info: params.info,
      key_ingredients: params.key_ingredients,
      link: params.link,
      photo_upload: params.photo_upload,
      time: params.time,
      title: params.title,
      vegan: params.vegan,
      vegetarian: params.vegetarian,
    };

    for (const prop in updateData) {
      //set props to null if they don't exist for the type
      if (prop !== 'type' && prop !== 'published') {
        if (!(fields_by_type[params.type] || []).includes(prop)) {
          updateData[prop] = null;
        }

        if (updateData[prop] === 'null') {
          updateData[prop] = null;
        }
      }
    }

    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/recommendationItems/${
        id === 'new' ? '' : id
      }`,
      {
        method: id === 'new' ? 'POST' : 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      },
    );
    if (res.status === 200) {
      const { recommendationItem } = await res.json();
      if (id === 'new') {
        history.push(`/recommendationItems/${recommendationItem.id}`);
      } else {
        getRecommendationItem();
      }
      setUpdated(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      if (Array.isArray(error)) {
        setErrors(prevArray => [...prevArray, ...error]);
      } else {
        setErrors(prevArray => [...prevArray, error]);
      }
    }
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (Math.round(file.size / 1024) > 2048) {
        //reset file upload
        event.target.value = null;
        setErrors(['Please upload a file under 2 MB.']);
        return;
      } else {
        setParams(prevState => ({
          ...prevState,
          photo_upload: reader.result,
        }));
      }
    };
  };

  useEffect(() => {
    setRoute('recommendationItems');
    if (id !== 'new') {
      getRecommendationItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selected_type_fields = fields_by_type[params.type] || [];

  if (!params) {
    return null;
  }

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Recommendation Item {id} - {params.title}
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  onClick={() => updateRecommendationItem()}
                  disabled={fetching}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Recommendation Item {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this recommendation item:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Recommendation Item information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Type</label>
                          <select
                            value={params.type}
                            name='Type'
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                type: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          >
                            <option disabled selected>
                              -- Choose a type --
                            </option>
                            {recommendationItemTypes.map((type, index) => {
                              return (
                                <option key={index} value={type}>
                                  {type}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      {params.type && (
                        <div className='mt-4 flex-1 max-w-full'>
                          <div className='flex'>
                            <input
                              className='cursor-pointer w-4'
                              type='checkbox'
                              checked={params.published}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  published: Number(e.target.checked),
                                }))
                              }
                            />
                            <label className='ml-2 pt-1 text-sm'>
                              Published
                              <label className='pl-1 text-xs opacity-60'>
                                (Only published items will be shown to users)
                              </label>
                            </label>
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('title') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>Title</label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              (Character limit 60)
                            </label>
                            <input
                              placeholder='Title'
                              value={params.title}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  title: e.target.value,
                                }))
                              }
                              maxLength='60'
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('description') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div
                            className='flex flex-col relative'
                            data-color-mode='light'
                          >
                            <label className='p-0 text-sm'>Description</label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              Shows below title
                            </label>
                            <MarkdownEditor
                              height={200}
                              value={params.description}
                              onChange={value =>
                                setParams(prevState => ({
                                  ...prevState,
                                  description: value,
                                }))
                              }
                              highlightEnable={false}
                              autoSyncScroll={true}
                            />
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('photo_upload') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>Photo</label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              Photo that will display in app. If none, we’ll
                              show the default image (png/jpeg only)
                            </label>

                            {params.photo_url ? (
                              <div className='relative inline-block w-28 mb-2'>
                                <img
                                  src={params.photo_url}
                                  alt='Photo Url'
                                  className='w-full h-auto'
                                />

                                <button
                                  onClick={() =>
                                    setParams(prevState => ({
                                      ...prevState,
                                      photo_url: null,
                                      photo_upload: '',
                                    }))
                                  }
                                  className='absolute top-0 right-0 p-2 bg-white bg-opacity-50 hover:bg-gray-300 hover:bg-opacity-75'
                                >
                                  <img
                                    src={X}
                                    alt='Delete'
                                    className='w-4 h-4'
                                  />
                                </button>
                              </div>
                            ) : (
                              <input
                                type='file'
                                accept='image/png, image/jpeg'
                                onChange={handleFileUpload}
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('key_ingredients') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>
                              Key Ingredients
                            </label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              Only to be used internally for now. A list we can
                              use to search for applicable products
                            </label>
                            <input
                              placeholder='Key Ingredients'
                              value={params.key_ingredients}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  key_ingredients: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('info') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>Info</label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              Will show next to the info icon (e.g. Contains
                              trace amounts of cows milk) (Character limit 30)
                            </label>
                            <input
                              placeholder='Info'
                              value={params.info}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  info: e.target.value,
                                }))
                              }
                              maxLength='30'
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('age_low') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>Age Range Low</label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              0-6m old
                            </label>
                            <input
                              type='number'
                              placeholder='Age Range Low'
                              value={params.age_log}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  age_low: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('age_high') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>
                              Age Range High
                            </label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              0-6m old
                            </label>
                            <input
                              type='number'
                              placeholder='Age Range High'
                              value={params.age_high}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  age_high: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('time') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>Time</label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              Estimated time to complete. Shown with clock icon.
                              (e.g. “30 mins”) (Character limit 30)
                            </label>
                            <input
                              placeholder='Time'
                              value={params.time}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  time: e.target.value,
                                }))
                              }
                              maxLength='30'
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('link') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>Link</label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              Link to the 3rd party site. Use affiliate code if
                              we have one
                            </label>
                            <input
                              placeholder='Link'
                              value={params.link}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  link: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      )}

                      {selected_type_fields.includes('discount_code') && (
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='p-0 text-sm'>Discount Code</label>
                            <label className='mb-1 p-0 text-xs opacity-60'>
                              {`For discount code info (e.g. "Use code TINY20 for
                              20% off") (Character limit 30)`}
                            </label>
                            <input
                              placeholder='Discount Code'
                              value={params.discount_code}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  discount_code: e.target.value,
                                }))
                              }
                              maxLength='30'
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {(params.type === 'probiotic' ||
                    params.type === 'supplement') && (
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            Allergens
                          </h2>
                          <label className='mb-1 p-0 text-sm opacity-60'>
                            {`To be used internally for now. In the future may use
                            with a filter feature. "True" means the product
                            contains the allergen. Use "Unknown" if not listed
                            on the product info.`}
                          </label>
                        </div>

                        {selected_type_fields.includes(
                          'allergens_cows_milk',
                        ) && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Allergens Cow Milk
                              </label>
                              <select
                                value={params.allergens_cows_milk}
                                name='Allergens Cow Milk'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    allergens_cows_milk: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {selected_type_fields.includes('allergens_gluten') && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Allergens Gluten
                              </label>
                              <select
                                value={params.allergens_gluten}
                                name='Allergens Gluten'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    allergens_gluten: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {selected_type_fields.includes('allergens_egg') && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Allergens Egg
                              </label>
                              <select
                                value={params.allergens_egg}
                                name='Allergens Egg'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    allergens_egg: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {selected_type_fields.includes('allergens_soy') && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Allergens Soy
                              </label>
                              <select
                                value={params.allergens_soy}
                                name='Allergens Soy'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    allergens_soy: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {selected_type_fields.includes('allergens_peanuts') && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Allergens Peanuts
                              </label>
                              <select
                                value={params.allergens_peanuts}
                                name='Allergens Peanuts'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    allergens_peanuts: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {selected_type_fields.includes(
                          'allergens_treenuts',
                        ) && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Allergens Treenuts
                              </label>
                              <select
                                value={params.allergens_treenuts}
                                name='Allergens Treenuts'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    allergens_treenuts: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {selected_type_fields.includes(
                          'allergens_shellfish',
                        ) && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Allergens Shellfish
                              </label>
                              <select
                                value={params.allergens_shellfish}
                                name='Allergens Shellfish'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    allergens_shellfish: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {selected_type_fields.includes('vegan') && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>Vegan</label>
                              <select
                                value={params.vegan}
                                name='Vegan'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    vegan: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {selected_type_fields.includes('vegetarian') && (
                          <div className='mt-4 flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Vegetarian
                              </label>
                              <select
                                value={params.vegetarian}
                                name='Vegetarian'
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    vegetarian: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              >
                                <option value={'null'}>Unknown</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

import X from '../../assets/iconography/x.svg';
import moment from 'moment';

export default function MediaUpload() {
  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(false);
  const [params, setParams] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);

  const getMediaUpload = async () => {
    setFetching(true);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/mediaUploads/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const { mediaUpload } = await res.json();

      setParams(mediaUpload);
      setFetching(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }
  };

  const updateMediaUpload = async () => {
    setErrors([]);
    setUpdated(false);

    const updateData = {
      name: params.name,
      type: params.type || 'action_plan_recommendations',
      upload: params.upload,
      color: params.color,
    };

    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/;
    if (updateData.color && !hexColorRegex.test(updateData.color)) {
      setErrors(['Invalid color, please enter valid hex format (#FFFFFF).']);
      return;
    }

    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/mediaUploads/${id === 'new' ? '' : id}`,
      {
        method: id === 'new' ? 'POST' : 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      },
    );
    if (res.status === 200) {
      const { mediaUpload } = await res.json();
      if (id === 'new') {
        history.push(`/mediaUploads/${mediaUpload.id}`);
      } else {
        getMediaUpload();
      }
      setUpdated(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      if (Array.isArray(error)) {
        setErrors(prevArray => [...prevArray, ...error]);
      } else {
        setErrors(prevArray => [...prevArray, error]);
      }
    }
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = oe => {
      if (Math.round(file.size / 1024) > 2048) {
        //reset file upload
        event.target.value = null;
        setErrors(['Please upload a file under 2 MB.']);
        return;
      } else {
        setParams(prevState => ({
          ...prevState,
          upload: reader.result,
        }));

        parseBackgroundColor(oe.target.result);
      }
    };
  };

  const parseBackgroundColor = data => {
    try {
      if (data.includes('image/svg+xml')) {
        // Decode the base64 string and parse the SVG file to get the background color
        const decodedData = atob(data.split(',')[1]);
        const parser = new DOMParser();
        const doc = parser.parseFromString(decodedData, 'image/svg+xml');
        const rect = doc.querySelector('rect');
        if (rect) {
          const color = rect.getAttribute('fill');

          if (color) {
            setParams(prevState => ({
              ...prevState,
              color,
            }));
          }
        }
      } else {
        const img = new Image();
        img.src = data;
        img.onload = function () {
          // Create a hidden canvas to draw the image on
          const canvas = document.createElement('canvas');
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          // Draw the image on the canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(this, 0, 0, this.naturalWidth, this.naturalHeight);

          // Get the pixel data from the canvas
          const pixelData = ctx.getImageData(
            0,
            0,
            this.naturalWidth,
            this.naturalHeight,
          ).data;

          // Sample the color of the first pixel
          const color = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);

          if (color) {
            setParams(prevState => ({
              ...prevState,
              color,
            }));
          }
        };
      }
    } catch (err) {
      console.log('Unable to parse color', err);
    }
  };

  const rgbToHex = (r, g, b) => {
    return '#' + ((1 << 24) | ((r << 16) | (g << 8) | b)).toString(16).slice(1);
  };

  useEffect(() => {
    setRoute('mediaUploads');
    if (id !== 'new') {
      getMediaUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!params) {
    return null;
  }

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Media Upload {id}
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  onClick={() => updateMediaUpload()}
                  disabled={fetching}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Media Upload {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this media upload:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Media information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Name</label>
                          <input
                            placeholder='Name'
                            value={params.name}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                name: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Type</label>
                          {id === 'new' ? (
                            <select
                              value={params.type ?? ''}
                              name='Type'
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  type: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            >
                              <option value='' disabled>
                                -- Choose a type --
                              </option>
                              <option value='action_plan_recommendations'>
                                Action Plan Recommendation
                              </option>
                              <option value='key_insights_sections'>
                                Key Insight Section
                              </option>
                              <option value='partner_logo'>Partner Logo</option>
                              <option value='pdf_research_consent'>
                                PDF Research Consent
                              </option>
                              <option value='pdf_sample_reports'>
                                PDF Sample Reports
                              </option>
                            </select>
                          ) : (
                            <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                              {params.type}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='p-0 text-sm'>Image Upload</label>
                          <label className='mb-1 p-0 text-xs opacity-60'>
                            (png/jpeg/svg only)
                          </label>

                          {params.url ? (
                            <div className='relative inline-block w-28 mb-2'>
                              <img
                                src={params.url}
                                alt='Image Url'
                                className='w-full h-auto'
                              />

                              <button
                                onClick={() =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    url: null,
                                    upload: '',
                                  }))
                                }
                                className='absolute top-0 right-0 p-2 bg-white bg-opacity-50 hover:bg-gray-300 hover:bg-opacity-75'
                              >
                                <img src={X} alt='Delete' className='w-4 h-4' />
                              </button>
                            </div>
                          ) : (
                            <input
                              type='file'
                              accept='.jpeg,.jpg,.png,.svg'
                              onChange={handleFileUpload}
                            />
                          )}
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Color</label>
                          <div className='flex'>
                            <div className='flex grow'>
                              <input
                                placeholder='Color'
                                value={params.color}
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    color: e.target.value,
                                  }))
                                }
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              />
                            </div>
                            <div className='ml-2'>
                              <div
                                className='h-full w-20 border-2 border-black rounded text-center pt-1'
                                style={{
                                  backgroundColor: params.color,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import Loader from 'react-spinners/HashLoader';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import { queryBuilder } from '../../lib/utils/queryBuilder';

import Table from '../../components/Table';
import { formatDate } from '../../lib/utils/formatDate';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import KitSection from '../../components/KitSection/KitSection';
import PractitionerDropdown from '../../components/PractitionerDropdown/PractitionerDropdown';

import { checkValidDates } from '../../helpers';

export default function TinyAccount() {
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(true);
  const [birthdate, setBirthdate] = useState(null);
  const [expectedDueDate, setExpectedDueDate] = useState(null);
  const [nextSampleDate, setNextSampleDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [practitioners, setPractitioners] = useState([]);
  const [fetchingPractitioners, setFetchingPractitioners] = useState(true);
  const [products, setProducts] = useState([]);
  const [ttcDate, setTtcDate] = useState(null);
  const [openCalendar, setOpenCalendar] = useState();
  const [params, setParams] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [practitionersErrors, setPractitionersErrors] = useState([]);
  const updateTinyaccount = async () => {
    setErrors([]);
    setFetching(true);
    setUpdated(false);

    //commenting out for now, bug with checkValidDates with dateFormatRegex using calendar component
    // try {
    //   checkValidDates(params, [
    //     'birthdate',
    //     'expected_due_date',
    //     'delivery_date',
    //     'ttc_date',
    //   ]);
    // } catch (err) {
    //   setErrors([err.message]);
    //   setFetching(false);
    //   return;
    // }

    const res = await fetch(`${API_BASE_PATH}/ops/admins/tinyaccounts/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (res.status === 200) {
      setUpdated(true);
      setFetching(false);
      reset();
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }
  };
  useEffect(() => {
    async function getTinyaccount() {
      setFetching(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/tinyaccounts/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { tinyaccount } = await res.json();
        setParams(tinyaccount);
        setBirthdate(
          tinyaccount.birthdate
            ? moment(tinyaccount?.birthdate).toDate()
            : null,
        );
        setExpectedDueDate(
          tinyaccount.expected_due_date
            ? moment(tinyaccount?.expected_due_date).toDate()
            : null,
        );
        setNextSampleDate(
          tinyaccount.next_sample_date
            ? moment(tinyaccount?.next_sample_date).toDate()
            : null,
        );
        setDeliveryDate(
          tinyaccount.delivery_date
            ? moment(tinyaccount?.delivery_date).toDate()
            : null,
        );
        setTtcDate(
          tinyaccount.ttc_date ? moment(tinyaccount?.ttc_date).toDate() : null,
        );
        //prepare breadcrumbs
        let breadcrumbsArray = [];
        if (tinyaccount?.mainaccount?.id) {
          breadcrumbsArray.push({
            display: tinyaccount?.mainaccount?.email,
            link: `/mainaccounts/${tinyaccount?.mainaccount?.id}`,
          });
        } else {
          breadcrumbsArray.push({ display: 'no mainaccount yet' });
        }
        breadcrumbsArray.push({
          display: tinyaccount.first_name + ' ' + tinyaccount.last_name,
        });
        setBreadcrumbs(breadcrumbsArray);
        setFetching(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetching(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetching(false);
      }
    }
    setRoute('tinyaccounts');
    getTinyaccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getProducts() {
      setFetching(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/products?${queryBuilder({
          filterType: 'is_program',
          filterValue: 1,
        })}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { products } = await res.json();
        setProducts(products);
        setFetching(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetching(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetching(false);
      }
    }
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function getPractitionersList() {
      setFetchingPractitioners(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/practitioners?${queryBuilder({
          orderBy: 'user.first_name',
          order: 'asc',
        })}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { practitioners } = await res.json();

        setPractitioners(practitioners);
        setFetchingPractitioners(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setPractitionersErrors([
          'There was a problem loading the practitioners list, please try again later',
        ]);
        setFetchingPractitioners(false);
      } else {
        const { error } = await res.json();
        setPractitionersErrors(prevArray => [...prevArray, error]);
        setFetchingPractitioners(false);
      }
    }
    getPractitionersList();
  }, []);

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      first_name: params.first_name,
      last_name: params.last_name,
      sex: params.sex,
    },
  });

  const dirtyFields = formState.dirtyFields;

  const researchTableColumns = [
    {
      Header: 'Consent given on',
      accessor: row => formatDate(row.created_at),
    },
    {
      Header: 'Study ID',
      accessor: 'research_study_id',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ];

  const productOptions = products.map(product => ({
    value: product.id,
    title: product.name,
  }));

  productOptions.push({ value: '', title: 'None' });

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='sticky top-0 z-30 pb-2 w-full bg-gray-50'>
            <Breadcrumbs items={breadcrumbs} />
            <div className='flex justify-between'>
              <div className='mt-0 flex-auto self-center'>
                <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                  Tiny Account {id}
                </h1>
              </div>
              <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
                <NavLink className='ml-2 lg:ml-4 mt-0 flex' to='/tinyaccounts'>
                  <button className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'>
                    Discard
                  </button>
                </NavLink>
                <div className='ml-2 lg:ml-4 mt-0 flex'>
                  <button
                    type='submit'
                    onClick={handleSubmit(updateTinyaccount)}
                    disabled={fetching}
                    className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75'
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3 px-1'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Tiny account {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this tiny account:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              {fetching ? (
                <div className='flex justify-center items-center w-full m-10'>
                  <Loader color='#009b87' size='75px' speedMultiplier='0.75' />
                </div>
              ) : (
                <div className='flex flex-col lg:flex-row justify-center items-start lg:flex-1'>
                  <div className='flex flex-col mt-6 lg:flex-2 flex-shrink-0 w-full'>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            General information
                          </h2>
                        </div>
                        <div className='flex-1 max-w-full'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Tiny Account Id
                            </label>
                            <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                              {id}
                            </span>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Main Account Id
                            </label>
                            <NavLink
                              to={`/mainaccounts/${params.mainaccount_id}`}
                              className='flex-auto relative w-full m-0 py-2 text-sm hover:text-purple-300'
                            >
                              {params.mainaccount_id
                                ? params.mainaccount_id
                                : 'N/A'}
                            </NavLink>
                          </div>
                        </div>

                        <TextInput
                          title='First Name'
                          register={{
                            ...register('first_name', {
                              onChange: e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  first_name: e.target.value,
                                })),
                            }),
                          }}
                          placeholder='Ada'
                          value={params.first_name}
                          isDirty={dirtyFields.first_name}
                        />

                        <TextInput
                          title='Last Name'
                          register={{
                            ...register('last_name', {
                              onChange: e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  last_name: e.target.value,
                                })),
                            }),
                          }}
                          placeholder='Lovelace'
                          value={params.last_name}
                          isDirty={dirtyFields.last_name}
                        />

                        <SelectInput
                          title='Sex'
                          value={params.sex}
                          register={{
                            ...register('sex', {
                              onChange: e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  sex: e.target.value,
                                })),
                            }),
                          }}
                          isDirty={dirtyFields.sex}
                          options={[
                            { value: 'M', title: 'Male' },
                            { value: 'F', title: 'Female' },
                            { value: 'O', title: 'Other' },
                          ]}
                        />

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Birthdate
                            </label>
                            <input
                              placeholder='March 4th 2007'
                              value={
                                !birthdate
                                  ? null
                                  : moment(birthdate).format('YYYY-MM-DD')
                              }
                              onClick={() => {
                                setOpenCalendar(
                                  openCalendar === 'birthdate'
                                    ? null
                                    : 'birthdate',
                                );
                              }}
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                            <div
                              className={
                                openCalendar === 'birthdate'
                                  ? 'absolute z-50 top-20 right-0'
                                  : 'hidden'
                              }
                            >
                              <Calendar
                                value={birthdate}
                                onChange={value => {
                                  setBirthdate(value);
                                  setParams(prevState => ({
                                    ...prevState,
                                    birthdate:
                                      moment(value).format('YYYY-MM-DD'),
                                  }));
                                  setOpenCalendar(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Practitioner (and all related kits)
                            </label>
                            {fetching || fetchingPractitioners ? (
                              'Loading practitioners list...'
                            ) : practitioners.length > 0 ? (
                              <PractitionerDropdown
                                defaultValue={params.practitioner_id}
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    practitioner_id: e?.value ?? null ,
                                  }))
                                }
                                practitioners={practitioners}
                              />
                            ) : (
                              <>
                                <p>{params.practitioner_id}</p>
                                <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                                  <div className='flex flex-col p-3'>
                                    <div className='ml-4 pl-4'>
                                      <ul className='list-disc'>
                                        {practitionersErrors?.map(
                                          (error, idx) => (
                                            <li className='mt-2' key={idx}>
                                              {error}
                                            </li>
                                          ),
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <SelectInput
                      title='Next Suggested Program'
                      value={params.suggested_next_program}
                      register={{
                        ...register('suggested_next_program', {
                          onChange: e =>
                            setParams(prevState => ({
                              ...prevState,
                              suggested_next_program: e.target.value,
                            })),
                        }),
                      }}
                      isDirty={dirtyFields.suggested_next_program}
                      options={productOptions}
                    />

                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            Next sample
                          </h2>
                        </div>
                        <div className='flex-1 max-w-full'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Next sample date
                            </label>
                            <input
                              placeholder='March 4th 2024'
                              value={
                                !nextSampleDate
                                  ? null
                                  : moment(nextSampleDate).format('YYYY-MM-DD')
                              }
                              onClick={() => {
                                setOpenCalendar(
                                  openCalendar === 'nextSampleDate'
                                    ? null
                                    : 'nextSampleDate',
                                );
                              }}
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                            <div
                              className={
                                openCalendar === 'nextSampleDate'
                                  ? 'absolute z-50 top-20 right-0'
                                  : 'hidden'
                              }
                            >
                              <Calendar
                                value={nextSampleDate}
                                onChange={value => {
                                  setNextSampleDate(value);
                                  setParams(prevState => ({
                                    ...prevState,
                                    next_sample_date:
                                      moment(value).format('YYYY-MM-DD'),
                                  }));
                                  setOpenCalendar(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <TextInput
                          title='Next sample reason'
                          register={{
                            ...register('next_sample_reason', {
                              onChange: e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  next_sample_reason: e.target.value,
                                })),
                            }),
                          }}
                          placeholder='Reason for next sample'
                          value={params.next_sample_reason}
                          isDirty={dirtyFields.next_sample_reason}
                        />
                      </div>
                    </div>

                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            Other information
                          </h2>
                        </div>
                        <div className='flex-1 max-w-full'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Expected Due Date
                            </label>
                            <input
                              placeholder='March 4th 2007'
                              value={
                                !expectedDueDate
                                  ? null
                                  : moment(expectedDueDate).format('YYYY-MM-DD')
                              }
                              onClick={() => {
                                setOpenCalendar(
                                  openCalendar === 'expectedDueDate'
                                    ? null
                                    : 'expectedDueDate',
                                );
                              }}
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                            <div
                              className={
                                openCalendar === 'expectedDueDate'
                                  ? 'absolute z-50 top-20 right-0'
                                  : 'hidden'
                              }
                            >
                              <Calendar
                                value={expectedDueDate}
                                onChange={value => {
                                  setExpectedDueDate(value);
                                  setParams(prevState => ({
                                    ...prevState,
                                    expected_due_date:
                                      moment(value).format('YYYY-MM-DD'),
                                  }));
                                  setOpenCalendar(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Delivery Date
                            </label>
                            <input
                              placeholder='March 4th 2007'
                              value={
                                !deliveryDate
                                  ? null
                                  : moment(deliveryDate).format('YYYY-MM-DD')
                              }
                              onClick={() => {
                                setOpenCalendar(
                                  openCalendar === 'deliveryDate'
                                    ? null
                                    : 'deliveryDate',
                                );
                              }}
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                            <div
                              className={
                                openCalendar === 'deliveryDate'
                                  ? 'absolute z-50 top-20 right-0'
                                  : 'hidden'
                              }
                            >
                              <Calendar
                                value={deliveryDate}
                                onChange={value => {
                                  setDeliveryDate(value);
                                  setParams(prevState => ({
                                    ...prevState,
                                    delivery_date:
                                      moment(value).format('YYYY-MM-DD'),
                                  }));
                                  setOpenCalendar(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>TTC Date</label>
                            <input
                              placeholder='March 4th 2007'
                              value={
                                !ttcDate
                                  ? null
                                  : moment(ttcDate).format('YYYY-MM-DD')
                              }
                              onClick={() => {
                                setOpenCalendar(
                                  openCalendar === 'ttcDate' ? null : 'ttcDate',
                                );
                              }}
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                            <div
                              className={
                                openCalendar === 'ttcDate'
                                  ? 'absolute z-50 top-20 right-0'
                                  : 'hidden'
                              }
                            >
                              <Calendar
                                value={ttcDate}
                                onChange={value => {
                                  setTtcDate(value);
                                  setParams(prevState => ({
                                    ...prevState,
                                    ttc_date:
                                      moment(value).format('YYYY-MM-DD'),
                                  }));
                                  setOpenCalendar(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Birth Mother ID
                            </label>
                            <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                              {params.item_name ? params.item_name : `N/A`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            Research Consent
                          </h2>
                          {params?.research_participants?.length > 0 ? (
                            <div className='flex-1 max-w-full'>
                              <div className='flex flex-col relative'>
                                <div className='w-full border-collapse'>
                                  <Table
                                    type={'research_participants'}
                                    columns={researchTableColumns}
                                    staticData={params.research_participants}
                                    disabled={filter => true}
                                  ></Table>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex lg:mt-6 mt-4 lg:ml-8 lg:flex-1 flex-col w-full'>
                    <KitSection kits={params?.kits} />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import useStore from '../../stores';

import Table from '../../components/Table';

import FieldValue from '../../components/FieldValue/FieldValue';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

import { queryBuilder } from '../../lib/utils/queryBuilder';
import { API_PAGE_LIMIT } from '../../lib/constants';

export default function Log() {
  const route = 'logs';

  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    fetchDataAndReturn,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, 'log');
  const [logChanges, setLogChanges] = useState([]);
  const [logs, setLogs] = useState({});

  useEffect(() => {
    setRoute(route);

    (async () => {
      const data = await fetchData();

      const diff = json_diff(data.original_value, data.updated_value);
      const changes = [];
      for (const [key, value] of Object.entries(diff)) {
        changes.push({
          column: key,
          before: value.before,
          after: value.after,
        });
      }
      setLogChanges(changes);

      const logsList = await fetchDataAndReturn(
        `logs?updated_table=${data.updated_table}&updated_id=${
          data.updated_id
        }&${queryBuilder({
          page: 0,
          pageLimit: API_PAGE_LIMIT,
        })}`,
        'logs',
      );
      setLogs(logsList || []);
    })();
  }, [id]);

  const json_diff = (before, after) => {
    const changes = {};

    const compare = (oldObj, newObj, root) => {
      Object.keys(newObj).forEach(key => {
        if (['created_at', 'updated_at'].includes(key)) {
          return;
        }

        const oldValue = oldObj ? oldObj[key] : undefined;
        const newValue = newObj[key];

        if (oldValue === undefined && newValue !== undefined) {
          changes[`${root}${key}`] = { before: oldValue, after: newValue };
        } else if (
          newValue !== null &&
          typeof newValue == 'object' &&
          !Array.isArray(newValue)
        ) {
          compare(oldValue, newValue, `${root}${key}.`);
        } else if (oldValue !== newValue) {
          if (
            typeof newValue === 'boolean' &&
            Number(oldValue) === Number(newValue)
          ) {
            return;
          }

          changes[`${root}${key}`] = { before: oldValue, after: newValue };
        }
      });
    };

    compare(before, after, '');

    return changes;
  };

  const changesTableColumns = [
    {
      Header: 'Column',
      accessor: 'column',
    },
    {
      Header: 'Before',
      accessor: 'before',
    },
    {
      Header: 'After',
      accessor: 'after',
    },
  ];
  if (!params?.id) return null;
  return (
    <PageDetailView
      header={`Update Log ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      backUrl={route}
    >
      <PageDetailContainer numColumns={2}>
        <PageDetailCard column={2} title={'Information'}>
          <FieldValue title='Id' value={id} />

          <FieldValue title='Updated Table' value={params.updated_table} />

          <FieldValue title='Updated ID' value={params.updated_id} />

          <FieldValue
            title='Updated By'
            value={`${params.admin?.first_name} ${params.admin?.last_name}`}
          />

          <FieldValue title='Created At' value={params.created_at} />

          <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
            <div className='rounded-md'>
              <h2 className='p-8 font-serif font-semibold m-0 text-lg'>
                Changes
              </h2>
              {logChanges?.length > 0 ? (
                <div className='flex-1 max-w-full'>
                  <div className='flex flex-col relative'>
                    <div className='w-full border-collapse'>
                      <Table
                        type={'changes'}
                        columns={changesTableColumns}
                        staticData={logChanges}
                        disabled={() => true}
                      ></Table>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <FieldValue
            title='Original Value Raw'
            value={
              <pre className='whitespace-pre-wrap'>
                {JSON.stringify(params.original_value, null, 2)}
              </pre>
            }
          />

          <FieldValue
            title='Updated Value Raw'
            value={
              <pre className='whitespace-pre-wrap'>
                {JSON.stringify(params.updated_value, null, 2)}
              </pre>
            }
          />
        </PageDetailCard>

        <PageDetailCard
          column={1}
          title={'Updates'}
          className='lg:mt-6 mt-4 lg:ml-8'
        >
          {logs?.length > 0 ? (
            <div className='pt-4 pb-8'>
              {logs.map(update => {
                return (
                  <div key={update.id} className='first:mt-0 mt-4'>
                    <div className='flex-1 max-w-full break-all'>
                      <div className='flex flex-col relative'>
                        {update.id === params.id ? (
                          <div className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm bg-green-100'>
                            <p>Updated at:</p>
                            <span className='font-semibold'>
                              {update.created_at}
                            </span>
                          </div>
                        ) : (
                          <NavLink
                            to={`/logs/${update.id}`}
                            className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          >
                            <p>Updated at:</p>
                            <span className='font-semibold'>
                              {update.created_at}
                            </span>
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}

import { useState } from 'react';
import { API_BASE_PATH } from '../config/api';
import useStore from '../stores';
import updateLocalToken from '../lib/updateLocalToken';
import { queryBuilder } from '../lib/utils/queryBuilder';
import { OrderDirection, isValidOrderDirection } from '../types/order';

const useDataHandling = <T extends Record<string, any>>(
  path: string,
  id: string,
  fetchDataKey: string,
  params?: {
    page: number;
    pageLimit?: number;
    filterType?: string;
    filterValue?: string;
    orderBy?: string;
    order?: OrderDirection;
    searchTerm?: string;
    searchTermFilter?: string;
  },
) => {
  const [data, setData] = useState<T | null>(null);
  const token = useStore(state => state.admin.token || '');
  const admin = useStore(state => state.admin.admin);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const fetchData = async () => {
    setFetching(true);
    setUpdated(false);
    setErrors([]);

    // Validate order parameter
    if (params?.order && !isValidOrderDirection(params.order)) {
      setErrors(['Invalid order parameter. Must be "asc" or "desc"']);
      setFetching(false);
      return null;
    }

    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/${path}${id ? `/${id}` : ''}${
        params ? `?${queryBuilder(params)}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      },
    );

    if (res.status === 200) {
      const extended_token = res.headers.get('X-Access-Token');
      //if new access_token in response header, store in state
      if (extended_token) {
        updateLocalToken(extended_token);
        setAdmin(admin, extended_token);
      }

      const obj = await res.json();
      setData(obj[fetchDataKey] as T);
      setFetching(false);

      return obj[fetchDataKey] as T;
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }

    return null;
  };

  const fetchDataAndReturn = async (path: string, dataKey: string) => {
    const res = await fetch(`${API_BASE_PATH}/ops/admins/${path}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      const obj = await res.json();
      return obj[dataKey] as T;
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    }

    return null;
  };

  const saveData = async (sanitizeData: (obj: T) => T) => {
    setErrors([]);
    setUpdated(false);

    const updateData = sanitizeData(data as T);

    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/${path}/${id === 'new' ? '' : id}`,
      {
        method: id === 'new' ? 'POST' : 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      },
    );

    if (res.status === 200) {
      const obj = await res.json();
      setUpdated(true);

      return obj[fetchDataKey] as T;
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      if (Array.isArray(error)) {
        setErrors(prevArray => [...prevArray, ...error]);
      } else {
        setErrors(prevArray => [...prevArray, error]);
      }
    }

    return false;
  };

  return {
    data,
    setData,
    fetchData,
    fetchDataAndReturn,
    saveData,
    fetching,
    updated,
    errors,
  };
};

export default useDataHandling;

import { useMemo } from 'react';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown';

export default function PractitionerDropdown({
  defaultValue,
  onChange,
  practitioners,
}) {
  const selectOptions = useMemo(() => {
    if (practitioners?.length) {
      const practitionerOptions = practitioners.map(practitioner => {
        const user = practitioner.user;

        const name = user
          ? `${user.first_name} ${user.last_name}`
          : `${practitioner.first_name} ${practitioner.last_name}`;

        return {
          label: name,
          value: practitioner.id,
          email: practitioner.user?.email ?? practitioner.email,
        };
      });
      return practitionerOptions;
    }
    return [];
  }, [practitioners]);

  return (
    <SearchableDropdown
      defaultValue={defaultValue}
      onChange={onChange}
      selectOptions={selectOptions}
      searchKeys={['email', 'label']}
    />
  );
}

import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';

import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';

import moment from 'moment';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

import { queryBuilder } from '../../lib/utils/queryBuilder';
import { API_PAGE_LIMIT } from '../../lib/constants';

export default function Recommendation() {
  const route = 'recommendations';

  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    fetchDataAndReturn,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, 'recommendation');
  const [recommendationItems, setRecommendationItems] = useState([]);

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
    getOtherData();
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      description: params.description,
      display_title: params.display_title,
      name: params.name,
      items_lookup:
        params.items_lookup?.length > 0
          ? params.items_lookup.map((obj, index) => {
              return Object.assign({}, obj, {
                display_order: index,
              });
            })
          : [],
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };

  const getOtherData = async () => {
    const recommendationItemsList = await fetchDataAndReturn(
      `recommendationItems?${queryBuilder({
        page: 0,
        pageLimit: API_PAGE_LIMIT,
        orderBy: 'title',
        order: 'asc',
      })}`,
      'recommendationItems',
    );
    setRecommendationItems(recommendationItemsList || []);
  };

  const selectedItems = [];
  if (params?.items_lookup?.length > 0) {
    params.items_lookup.map(obj => {
      if (!obj.deleted) {
        selectedItems.push(obj.recommendation_item_id);
      }
    });
  }

  if (!params) {
    return null;
  }

  return (
    <PageDetailView
      header={`Recommendation ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      backUrl={route}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Information'}>
          <TextInput
            title='Name'
            placeholder='Internal name'
            value={params.name}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                name: e.target.value,
              }))
            }
          />

          <TextInput
            title='Display Title'
            placeholder='Title'
            value={params.display_title}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                display_title: e.target.value,
              }))
            }
          />

          <TextInput
            type='textarea'
            title='Description'
            subtitle='(Character limit 250)'
            placeholder='Description'
            value={params.description}
            maxLength={250}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                description: e.target.value,
              }))
            }
          />

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Items</label>
              {params?.items_lookup?.length > 0 ? (
                <>
                  {params?.items_lookup.map((recommendation_item, index) => {
                    if (recommendation_item.deleted) {
                      return null;
                    }
                    return (
                      <div key={index} className={'flex justify-space'}>
                        <Select
                          className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                          classNamePrefix='select'
                          value={recommendationItems.find(
                            obj =>
                              parseInt(obj.id) ===
                              parseInt(
                                recommendation_item.recommendation_item_id,
                              ),
                          )}
                          isClearable={false}
                          isSearchable={true}
                          name='recommendation_items'
                          placeholder='-- Choose a recommendation item --'
                          options={recommendationItems}
                          getOptionLabel={option =>
                            `${option.type}: ${
                              option.title || option.description
                            }`
                          }
                          getOptionValue={option => option.id}
                          onChange={e => {
                            const selectedItem = parseInt(e.id);
                            const newList = params.items_lookup.slice();

                            if (!selectedItems.includes(selectedItem)) {
                              if (newList[index]) {
                                newList[index].recommendation_item_id =
                                  selectedItem;
                              }
                            }
                            setParams(prevState => ({
                              ...prevState,
                              items_lookup: newList,
                            }));
                          }}
                        />
                        <button
                          type='button'
                          className='flex h-10 w-20 ml-2 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
                          onClick={() => {
                            const newList = params.items_lookup.slice();

                            if (newList[index]) {
                              if (newList[index].id > 0) {
                                newList[index].deleted = true;
                              } else {
                                newList.splice(index, 1);
                              }
                            }
                            setParams(prevState => ({
                              ...prevState,
                              items_lookup: newList,
                            }));
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    );
                  })}
                </>
              ) : null}
              <Select
                className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                classNamePrefix='select'
                value={0}
                isClearable={true}
                isSearchable={true}
                name='recommendation_items'
                placeholder='-- Choose a recommendation item --'
                options={recommendationItems}
                getOptionLabel={option =>
                  `${option.type}: ${option.title || option.description}`
                }
                getOptionValue={option => option.id}
                onChange={e => {
                  const selectedItem = parseInt(e.id);
                  const newList =
                    params?.items_lookup?.length > 0
                      ? params.items_lookup.slice()
                      : [];

                  if (!selectedItems.includes(selectedItem)) {
                    newList.push({
                      id: -1,
                      recommendation_id: params.id,
                      recommendation_item_id: parseInt(e.id),
                    });
                  }
                  setParams(prevState => ({
                    ...prevState,
                    items_lookup: newList,
                  }));
                }}
              />
            </div>
          </div>
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}

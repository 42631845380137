import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import './styles.css';
export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  type?: 'text' | 'password' | 'email' | 'number' | 'textarea';
  title?: string;
  subtitle?: string;
  placeholder?: string;
  value?: string | number | null;
  disabled?: boolean;
  maxLength?: number;
  register?: UseFormRegisterReturn;
  isDirty?: boolean;
  error?: string;
}
export default function TextInput({
  type,
  title,
  subtitle,
  placeholder,
  value,
  maxLength,
  disabled = false,
  onChange,
  className,
  register,
  isDirty = false,
  error,
  ...props
}: TextInputProps) {
  return (
    <div className='flex-1 max-w-full mt-4'>
      <div className='flex flex-col relative'>
        {title && (
          <label className={`${subtitle ? '' : 'mb-1'} p-0 text-sm`}>
            {title}
          </label>
        )}
        {subtitle && (
          <label className='mb-1 p-0 text-xs opacity-60'>{subtitle}</label>
        )}
        {type === 'textarea' ? (
          register ? (
            <textarea
              {...register}
              placeholder={placeholder}
              value={value ?? ''}
              maxLength={maxLength}
              disabled={disabled}
              className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className} ${
                isDirty ? 'dirty' : ''
              }`}
              {...props}
            />
          ) : (
            <textarea
              placeholder={placeholder}
              value={value ?? ''}
              maxLength={maxLength}
              onChange={onChange}
              disabled={disabled}
              className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className}`}
              {...props}
            />
          )
        ) : register ? (
          <input
            {...register}
            type={type ? type : 'text'}
            placeholder={placeholder}
            value={value ?? ''}
            maxLength={maxLength}
            disabled={disabled}
            className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className} ${
              isDirty ? 'dirty' : ''
            }`}
            {...props}
          />
        ) : (
          <input
            type={type ? type : 'text'}
            placeholder={placeholder}
            value={value ?? ''}
            maxLength={maxLength}
            onChange={onChange}
            disabled={disabled}
            className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className}`}
            {...props}
          />
        )}
        {error && <span className='text-red-500 text-xs mt-1'>{error}</span>}
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';
import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';

import moment from 'moment';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

import { queryBuilder } from '../../lib/utils/queryBuilder';
import { API_PAGE_LIMIT } from '../../lib/constants';

interface ConsultAgentData {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  credentials: string;
  description: string;
  head_shot_url: string;
  calendly_url: string;
  is_active: number;
}

export default function ConsultAgent() {
  const route = 'consultAgents';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling<ConsultAgentData>('consult-agents', id, 'consult_agent');

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
  }, [id]);

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      credentials: '',
      description: '',
      head_shot_url: '',
      calendly_url: '',
      is_active: 0,
    },
  });

  const dirtyFields = formState.dirtyFields;

  const onSave = async () => {
    const updatedObj = await saveData((params: ConsultAgentData) => ({
      id: params.id,
      email: params.email,
      first_name: params.first_name,
      last_name: params.last_name,
      credentials: params.credentials,
      description: params.description,
      head_shot_url: params.head_shot_url,
      calendly_url: params.calendly_url,
      is_active: params.is_active,
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
        reset(undefined, { keepValues: true, keepDirty: false });
      }
    }
  };

  return (
    <PageDetailView
      header={`Consult Agent ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      fnSave={handleSubmit(onSave)}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <TextInput
            title='Email'
            placeholder='Email'
            value={params?.email ?? ''}
            isDirty={dirtyFields.email}
            disabled={id !== 'new'}
            register={{
              ...register('email', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    email: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='First Name'
            placeholder='First Name'
            value={params?.first_name ?? ''}
            isDirty={dirtyFields.first_name}
            register={{
              ...register('first_name', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    first_name: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='Last Name'
            placeholder='Last Name'
            value={params?.last_name ?? ''}
            isDirty={dirtyFields.last_name}
            register={{
              ...register('last_name', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    last_name: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='Credentials'
            placeholder='Credentials'
            value={params?.credentials ?? ''}
            isDirty={dirtyFields.credentials}
            register={{
              ...register('credentials', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    credentials: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='Description'
            subtitle='(Character limit 255)'
            placeholder='Description'
            value={params?.description ?? ''}
            isDirty={dirtyFields.description}
            register={{
              ...register('description', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    description: e.target.value,
                  })),
              }),
            }}
            maxLength={255}
          />

          <TextInput
            title='Head Shot URL'
            placeholder='Head Shot URL'
            value={params?.head_shot_url ?? ''}
            isDirty={dirtyFields.head_shot_url}
            register={{
              ...register('head_shot_url', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    head_shot_url: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='Calendly URL'
            placeholder='Calendly URL'
            value={params?.calendly_url ?? ''}
            isDirty={dirtyFields.calendly_url}
            register={{
              ...register('calendly_url', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    calendly_url: e.target.value,
                  })),
              }),
            }}
          />

          <CheckboxInput
            title='Is Active'
            checked={Boolean(params?.is_active ?? false)}
            isDirty={dirtyFields.is_active}
            register={{
              ...register('is_active', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    is_active: Number(e.target.checked),
                  })),
              }),
            }}
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}

import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useStore from '../../stores';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

export default function ReportTaxaCategory() {
  const route = 'reportTaxaCategories';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, route);

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      display_name: params.display_name,
      category_gut: params.category_gut,
      category_vaginal: params.category_vaginal,
      about_gut: params.about_gut,
      about_vaginal: params.about_vaginal,
      evidence_rating_gut: params.evidence_rating_gut,
      evidence_rating_vaginal: params.evidence_rating_vaginal,
    }));
    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };
  if (!params) {
    return null;
  }
  return (
    <PageDetailView
      header={`Report Taxa Category ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <TextInput
            title='Taxonomy Category'
            placeholder='Taxonomy Category'
            value={params?.taxonomy_category}
            disabled
          />
          <TextInput
            title='Taxonomy Name'
            placeholder='Taxonomy Name'
            value={params?.taxonomy_name}
            disabled
          />
          {/* Editable */}
          <TextInput
            type='text'
            title='Display name'
            placeholder='Display name'
            value={params.display_name}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                display_name: e.target.value,
              }))
            }
          />
          <SelectInput
            title='Category Gut'
            options={[
              { value: '', title: '-- Choose a group --', disabled: true },
              { value: 'Unfriendly', title: 'Unfriendly' },
              { value: 'Beneficial', title: 'Beneficial' },
              { value: 'Variable', title: 'Variable' },
              { value: 'Unknown', title: 'Unknown' },
            ]}
            value={params.category_gut ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                category_gut: e.target.value,
              }))
            }
          />
          <SelectInput
            title='Category Vaginal'
            options={[
              { value: '', title: '-- Choose a group --', disabled: true },
              { value: 'Disruptive', title: 'Disruptive' },
              { value: 'Protective', title: 'Protective' },
              { value: 'Variable', title: 'Variable' },
              { value: 'Unknown', title: 'Unknown' },
            ]}
            value={params.category_vaginal ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                category_vaginal: e.target.value,
              }))
            }
          />
        </PageDetailCard>
        <PageDetailCard column={1} title={'About Gut'}>
          <MarkdownEditor
            height={300}
            value={params.about_gut}
            onChange={(value: string) => {
              setParams(prevState => ({
                ...prevState,
                about_gut: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
        <PageDetailCard column={1} title={'About Vaginal'}>
          <MarkdownEditor
            height={300}
            value={params.about_vaginal}
            onChange={(value: string) => {
              setParams(prevState => ({
                ...prevState,
                about_vaginal: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>

        <PageDetailCard column={1} title={'Evidence Rating Gut'}>
          <MarkdownEditor
            height={300}
            value={params.evidence_rating_gut}
            onChange={(value: string) => {
              setParams(prevState => ({
                ...prevState,
                evidence_rating_gut: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
        <PageDetailCard column={1} title={'Evidence Rating Vaginal'}>
          <MarkdownEditor
            height={300}
            value={params.evidence_rating_vaginal}
            onChange={(value: string) => {
              setParams(prevState => ({
                ...prevState,
                evidence_rating_vaginal: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}

import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';
import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';
import SelectInput from '../../components/SelectInput/SelectInput';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

interface NoteSummaryDetailData {
  id?: string;
  title?: string;
  age_low?: number;
  age_high?: number;
  is_default?: boolean;
  mss_low?: number;
  mss_high?: number;
  priority?: number | null;
  published?: boolean;
  reason?: string;
  sampling_type?: string;
  section?: string;
  summary_text?: string;
  variables?: string;
}
export default function NoteSummaryDetail() {
  const route = 'noteSummary';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    fetchDataAndReturn,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling<Partial<NoteSummaryDetailData>>(
    route,
    id,
    'noteSummaryDetail',
  );

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      title: params.title,
      age_low: params.age_low,
      age_high: params.age_high,
      is_default: params.is_default,
      mss_low: params.mss_low,
      mss_high: params.mss_high,
      priority:
        params.priority && params.priority.toString().length > 0
          ? Number(params.priority)
          : null,
      published: params.published,
      reason: params.reason,
      sampling_type: params.sampling_type,
      section: params.section,
      summary_text: params.summary_text,
      variables: params.variables,
    }));
    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };
  if (!params) return null;
  return (
    <PageDetailView
      header={`Note Summary Detail ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <TextInput
            title='Title'
            placeholder='Title'
            value={params?.title}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                title: e.target.value,
              }))
            }
          />

          <CheckboxInput
            title='Published'
            checked={params?.published ?? false}
            onChange={e =>
              setParams(prevState => ({
                ...prevState!,
                published: e.target.checked,
              }))
            }
          />

          <TextInput
            title='Sampling Type'
            placeholder='Sampling Type'
            value={params.sampling_type}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                sampling_type: e.target.value,
              }))
            }
          />

          <TextInput
            type='number'
            title='Age Low'
            placeholder='Age Low'
            value={params.age_low}
            onChange={e =>
              setParams(prevState => ({
                ...prevState!,
                age_low: parseFloat(e.target.value) || 0,
              }))
            }
          />

          <TextInput
            type='number'
            title='Age High'
            placeholder='Age High'
            value={params.age_high}
            onChange={e =>
              setParams(prevState => ({
                ...prevState!,
                age_high: parseFloat(e.target.value) || 0,
              }))
            }
          />

          <TextInput
            type='number'
            title='Microbiome Summary Score Low'
            placeholder='MSS Low'
            value={params.mss_low}
            onChange={e =>
              setParams(prevState => ({
                ...prevState!,
                mss_low: parseFloat(e.target.value) || 0,
              }))
            }
          />

          <TextInput
            type='number'
            title='Microbiome Summary Score High'
            placeholder='MSS High'
            value={params.mss_high}
            onChange={e =>
              setParams(prevState => ({
                ...prevState!,
                mss_high: parseFloat(e.target.value) || 0,
              }))
            }
          />

          <TextInput
            title='Priority'
            placeholder='Priority'
            value={params.priority}
            onChange={e =>
              setParams(prevState => ({
                ...prevState!,
                priority: parseFloat(e.target.value) || 0,
              }))
            }
          />

          <SelectInput
            title='Section'
            options={[
              { value: '', title: '-- Choose a section --', disabled: true },
              { value: 'reason_v3', title: 'reason_v3' },
              { value: 'main_finding_v3', title: 'main_finding_v3' },
              { value: 'specialist_v3', title: 'specialist_v3' },
            ]}
            value={params.section ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                section: e.target.value,
              }))
            }
          />

          <CheckboxInput
            title='Is Default'
            checked={params?.is_default ?? false}
            onChange={e =>
              setParams(prevState => ({
                ...prevState!,
                is_default: e.target.checked,
              }))
            }
          />

          <TextInput
            title='Reason'
            placeholder='Reason'
            value={params.reason}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                reason: e.target.value,
              }))
            }
          />

          <TextInput
            title='Summary Text'
            placeholder='Summary Text'
            type='textarea'
            value={params.summary_text}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                summary_text: e.target.value,
              }))
            }
          />

          <TextInput
            title='Variables'
            placeholder='Variables'
            value={params.variables}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                variables: e.target.value,
              }))
            }
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}

import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import { useForm } from 'react-hook-form';
import { queryBuilder } from '../../lib/utils/queryBuilder';

import BackArrow from '../../assets/iconography/back_arrow.svg';
import KitSection from '../../components/KitSection/KitSection';
import ConsultSection from '../../components/ConsultSection/ConsultSection';
import X from '../../assets/iconography/x.svg';

import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';

import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';

import PartnerDropdown from '../../components/PartnerDropdown/PartnerDropdown';

export default function Practitioner() {
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(true);
  const [params, setParams] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showLicenseTypeOverlay, setShowLicenseTypeOverlay] = useState(false);
  const [selectedLicenseTypes, setSelectedLicenseTypes] = useState([]);
  const [fetchingPartners, setFetchingPartners] = useState(true);
  const [partners, setPartners] = useState([]);
  const [partnersErrors, setPartnersErrors] = useState([]);
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const [enums, setEnums] = useState({});

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const dirtyFields = formState.dirtyFields;

  const updatePractitioner = async () => {
    setErrors([]);
    setUpdated(false);
    const updateData = {
      level: params.level,
      status: params.status,
      code: params.code?.length > 0 ? params.code : null,
      comments: params.comments?.length > 0 ? params.comments : null,
      organization:
        params.organization?.length > 0 ? params.organization : null,
      license_number:
        params.license_number?.length > 0 ? params.license_number : null,
      license_type: params.license_type || [],
      hide_consults: params.hide_consults,
      npi: params.npi,
      minc: params.minc,
      partner_id: params.partner_id,
    };
    const res = await fetch(`${API_BASE_PATH}/ops/admins/practitioner/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    });
    if (res.status === 200) {
      setUpdated(true);
      reset();
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  useEffect(() => {
    async function getPractitioner() {
      setFetching(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/practitioner/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { practitioner } = await res.json();
        setParams(practitioner);
        setFetching(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetching(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetching(false);
      }
    }

    async function getPartnersList() {
      setFetchingPartners(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/partners?${queryBuilder({
          page: 0,
          pageLimit: 100,
          orderBy: 'name',
          order: 'asc',
        })}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { partners } = await res.json();
        setPartners(partners);
        setFetchingPartners(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setPartnersErrors([
          'There was a problem loading the partners list, please try again later',
        ]);
        setFetchingPartners(false);
      } else {
        const { error } = await res.json();
        setPartnersErrors(prevArray => [...prevArray, error]);
        setFetchingPartners(false);
      }
    }

    async function getEnums() {
      setFetchingEnums(true);
      const res = await fetch(`${API_BASE_PATH}/ops/admins/enums`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        const enums = await res.json();
        setEnums(enums);
        setFetchingEnums(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetchingEnums(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetchingEnums(false);
      }
    }

    setRoute('practitioners');
    getPractitioner();
    getPartnersList();
    getEnums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      {showLicenseTypeOverlay ? (
        <div className='fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-50 items-center flex justify-center'>
          <div className='m-10 lg:w-full lg:max-w-xl relative bg-white rounded-md border border-solid border-gray-500 py-4'>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-2 px-4'>
                <div className='p-2 items-center flex justify-center'>
                  <span className='font-serif text-lg'>Edit License Type</span>
                </div>
                <div
                  onClick={() => setShowLicenseTypeOverlay(false)}
                  className='ml-12 hover:bg-gray-300 hover:bg-opacity-50 rounded-md cursor-pointer p-2 items-center flex justify-center'
                >
                  <img
                    src={X}
                    className='relative block h-5 w-5'
                    alt='Pelago, smart packaging'
                  />
                </div>
              </div>
            </div>
            <div className='border-b border-solid border-gray-300 p-5'>
              <div className='w-full flex flex-col'>
                {enums.license_types?.map((license_type, idx) => (
                  <div key={idx} className='flex items-end justify-items-start'>
                    <div className='flex-1 max-w-full'>
                      <div className='flex'>
                        <input
                          className='cursor-pointer w-4'
                          type='checkbox'
                          checked={selectedLicenseTypes.includes(license_type)}
                          onChange={() => {
                            if (selectedLicenseTypes.includes(license_type)) {
                              setSelectedLicenseTypes(
                                selectedLicenseTypes.filter(
                                  item => item !== license_type,
                                ),
                              );
                            } else {
                              setSelectedLicenseTypes([
                                ...selectedLicenseTypes,
                                license_type,
                              ]);
                            }
                          }}
                        />
                        <label className='ml-2 pt-1 text-xs'>
                          {license_type}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='flex justify-end px-4 pt-4'>
              <button
                onClick={() => setShowLicenseTypeOverlay(false)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setParams(prevState => ({
                    ...prevState,
                    license_type: selectedLicenseTypes,
                  }));
                  setShowLicenseTypeOverlay(false);
                }}
                className='ml-4 flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-pink-700 hover:bg-pink-900 rounded-md border border-solid border-pink-900 text-white transition-all duration-200 ease-in-out delay-75'
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='sticky top-0 z-30 pb-2 w-full bg-gray-50'>
            <div className='flex justify-between'>
              <div className='flex-none max-w-full mr-2 lg:mr-6'>
                <NavLink
                  to='/practitioners'
                  className='flex relative items-center min-h-14 m-0 p-1 bg-transparent border border-solid border-gray-300 rounded-md'
                >
                  <div className='flex relative items-center justify-center p-0 m-0 bg-transparent h-8 w-8'>
                    <div className='m-0'>
                      <img
                        src={BackArrow}
                        className='relative block h-5 w-5'
                        alt='Tiny Ops'
                      />
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className='mt-0 flex-auto self-center'>
                <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                  Practitioner {params.id}
                </h1>
              </div>
              <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
                <NavLink className='ml-2 lg:ml-4 mt-0 flex' to='/practitioners'>
                  <button className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'>
                    Discard
                  </button>
                </NavLink>
                <div className='ml-2 lg:ml-4 mt-0 flex'>
                  <button
                    onClick={handleSubmit(updatePractitioner)}
                    type='submit'
                    disabled={fetching}
                    className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75'
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3 px-1'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Practitioner {params.id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this practitioner:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, idx) => (
                        <li className='mt-2' key={idx}>
                          {error}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col lg:flex-row justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-2 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          General information
                        </h2>
                      </div>
                      <div className='flex-1 max-w-full'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Id</label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            {id}
                          </div>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>First Name</label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            {params.user?.first_name}
                          </div>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Last Name</label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            {params.user?.last_name}
                          </div>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Email</label>
                          <div className='flex'>
                            <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                              {params.user?.email}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Phone Number
                          </label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            {params.user?.phone_number}
                          </div>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Date Joined
                          </label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            {params.created_at}
                          </div>
                        </div>
                      </div>

                      <TextInput
                        title='Organization'
                        register={{
                          ...register('organization', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                organization: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='Organization'
                        value={params.organization}
                        isDirty={dirtyFields.organization}
                      />

                      <SelectInput
                        title='Status'
                        value={params.status}
                        register={{
                          ...register('status', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                status: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.status}
                        options={[
                          {
                            title: 'Active',
                            value: 'active',
                          },
                          {
                            title: 'Inactive',
                            value: 'inactive',
                          },
                        ]}
                      />

                      <SelectInput
                        title='Level'
                        value={params.level}
                        register={{
                          ...register('level', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                level: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.level}
                        options={[
                          {
                            title: 'Member',
                            value: 'member',
                          },
                          {
                            title: 'Partner',
                            value: 'partner',
                          },
                          {
                            title: 'Prospective',
                            value: 'prospective',
                          },
                        ]}
                      />

                      <TextInput
                        title='Code'
                        register={{
                          ...register('code', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                code: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='Referral code'
                        value={params.code}
                        isDirty={dirtyFields.code}
                      />

                      <TextInput
                        title='License Number'
                        register={{
                          ...register('license_number', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                license_number: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='License number'
                        value={params.license_number}
                        isDirty={dirtyFields.license_number}
                      />

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex'>
                          <div className='flex flex-col relative w-full'>
                            <label className='mb-1 p-0 text-sm'>
                              License Type
                            </label>
                            <input
                              placeholder='License type'
                              value={params.license_type}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  license_type: e.target.value,
                                }))
                              }
                              disabled={true}
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                          <button
                            className='flex px-4 mt-6 ml-2 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
                            type='button'
                            disabled={fetchingEnums}
                            onClick={() => {
                              setSelectedLicenseTypes(
                                params.license_type || [],
                              );
                              setShowLicenseTypeOverlay(true);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>

                      <TextInput
                        title='NPI'
                        register={{
                          ...register('npi', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                npi: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='npi'
                        value={params.npi}
                        isDirty={dirtyFields.npi}
                      />

                      <TextInput
                        title='MINC'
                        register={{
                          ...register('minc', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                minc: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='minc'
                        value={params.minc}
                        isDirty={dirtyFields.minc}
                      />

                      <TextInput
                        title='Comments'
                        type='textarea'
                        register={{
                          ...register('comments', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                comments: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='Internal comments'
                        value={params.comments}
                        isDirty={dirtyFields.comments}
                      />

                      <CheckboxInput
                        title='Hide Consults'
                        subtitle=''
                        checked={params.hide_consults}
                        onChange={e =>
                          setParams(prevState => ({
                            ...prevState,
                            hide_consults: Number(e.target.checked),
                          }))
                        }
                      />

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Partner</label>
                          {fetching || fetchingPartners ? (
                            'Loading partners list...'
                          ) : !partnersErrors?.length ? (
                            <PartnerDropdown
                              defaultValue={params.partner_id}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  partner_id: e?.value,
                                }))
                              }
                              partners={partners}
                            />
                          ) : (
                            <>
                              <p>{params.partner_id}</p>
                              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                                <div className='flex flex-col p-3'>
                                  <div className='ml-4 pl-4'>
                                    <ul className='list-disc'>
                                      {partnersErrors.map((error, idx) => (
                                        <li className='mt-2' key={idx}>
                                          {error}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex lg:mt-6 mt-4 lg:ml-8 lg:flex-1 flex-col w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 flex flex-col flex-1'>
                    <div className='px-4 lg:px-8 pt-8'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        Assigned main accounts
                      </h2>
                    </div>
                    {params?.mainaccounts?.length > 0 ? (
                      <div className='px-4 lg:px-8 pt-4 pb-8'>
                        {params.mainaccounts.map(mainaccount => {
                          return (
                            <div
                              key={mainaccount.id}
                              className='first:mt-0 mt-4'
                              id={mainaccount.id}
                            >
                              <div className='flex-1 max-w-full break-all'>
                                <div className='flex flex-col relative'>
                                  <NavLink
                                    to={`/mainaccounts/${mainaccount.id}`}
                                    className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                                  >
                                    <span className='font-semibold'>
                                      {mainaccount.first_name}{' '}
                                      {mainaccount.last_name}
                                    </span>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className='px-4 lg:px-8 pt-4 pb-8'>
                        <div className='flex-1 max-w-full'>
                          <div className='flex flex-col relative'>
                            <span className='flex-auto relative w-full m-0 text-sm'>
                              This practitioner has no accounts
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='flex lg:mt-6 mt-4 lg:flex-1 flex-col w-full'>
                    <KitSection kits={params?.kits} title='Referred Kits' />
                  </div>

                  <div className='flex lg:mt-6 mt-4 lg:flex-1 flex-col w-full'>
                    <ConsultSection
                      consults={params?.consults}
                      title='Consults'
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

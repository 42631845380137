import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import './styles.css';

export default function SelectInput({
  title,
  subtitle,
  options,
  value,
  register,
  onChange,
  placeholder,
  className,
  isDirty,
  children,
}: {
  title: string;
  subtitle?: string;
  options: { value: string | number; title: string; disabled?: boolean }[];
  value?: string | number;
  register?: UseFormRegisterReturn;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  className?: string;
  isDirty?: boolean;
  children?: React.ReactNode;
}) {
  const optionMap = options?.map(option => (
    <option key={option.value} value={option.value} disabled={option.disabled}>
      {option.title}
    </option>
  ));

  return (
    <div className='flex-1 max-w-full mt-4'>
      <div className='flex flex-col relative'>
        <label className={`${subtitle ? '' : 'mb-1'} p-0 text-sm`}>
          {title}
        </label>
        {subtitle && (
          <label className='mb-1 p-0 text-xs opacity-60'>{subtitle}</label>
        )}
        {register ? (
          <select
            {...register}
            value={value ?? ''}
            className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className} ${
              isDirty ? 'dirty' : ''
            }`}
          >
            {placeholder && (
              <option value='' disabled>
                {placeholder}
              </option>
            )}
            {optionMap}
          </select>
        ) : (
          <select
            value={value ?? ''}
            name='Type'
            onChange={onChange}
            className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className}`}
          >
            {placeholder && (
              <option value='' disabled>
                {placeholder}
              </option>
            )}
            {optionMap}
          </select>
        )}
        {children}
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';

import useStore from '../../stores';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

import TextInput from '../../components/TextInput/TextInput';
import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import FieldValue from '../../components/FieldValue/FieldValue';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

import { queryBuilder } from '../../lib/utils/queryBuilder';
import { API_PAGE_LIMIT } from '../../lib/constants';

export default function Condition() {
  const route = 'conditions';

  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    fetchDataAndReturn,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, 'condition');
  const [guides, setGuides] = useState([]);

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
    getOtherData();
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      display_title: params.display_title,
      display_order: params.display_order,
      type: params.type,
      checkpoints: params.checkpoints,
      empty_state: params.empty_state,
      other_factors: params.other_factors,
      decreased_risk: params.decreased_risk,
      increased_risk: params.increased_risk,
      faq: params.faq,
      references: params.references,
      evidence_rating: params.evidence_rating,
      age_low: params.age_low,
      age_high: params.age_high,
      about: params.about,
      v2: params.v2,
      guides_lookup:
        params.guides_lookup?.length > 0
          ? params.guides_lookup.map((obj, index) => {
              return Object.assign({}, obj, {
                display_order: index,
              });
            })
          : [],
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };

  const getOtherData = async () => {
    const guidesList = await fetchDataAndReturn(
      `guides?${queryBuilder({
        page: 0,
        pageLimit: API_PAGE_LIMIT,
        orderBy: 'display_title',
        order: 'asc',
      })}`,
      'guides',
    );
    setGuides(guidesList || []);
  };

  const selectedGuides = [];
  if (params?.guides_lookup?.length > 0) {
    params.guides_lookup.map(obj => {
      if (!obj.deleted) {
        selectedGuides.push(obj.guide_id);
      }
    });
  }
  if (!params) {
    return null;
  }

  return (
    <PageDetailView
      header={`Condition ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      backUrl={route}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Information'}>
          <TextInput
            title='Display Title'
            placeholder='Title'
            value={params.display_title}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                display_title: e.target.value,
              }))
            }
          />

          <SelectInput
            title='Type'
            options={[
              { value: '', title: '-- Choose a type --', disabled: true },
              { value: 'biomarker', title: 'Biomarker' },
              { value: 'association', title: 'Association' },
            ]}
            value={params.type ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                type: e.target.value,
              }))
            }
          />

          <TextInput
            title='Checkpoints'
            placeholder='Checkpoints'
            value={params.checkpoints}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                checkpoints: e.target.value,
              }))
            }
          />

          <TextInput
            title='Empty State'
            placeholder='Empty State'
            value={params.empty_state}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                empty_state: e.target.value,
              }))
            }
          />

          <TextInput
            title='Display Order'
            placeholder='Display Order'
            value={params.display_order}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                display_order: e.target.value.replace(/\D/, ''),
              }))
            }
          />

          <TextInput
            title='Other Factors'
            placeholder='Other Factors'
            value={params.other_factors}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                other_factors: e.target.value,
              }))
            }
          />

          <SelectInput
            title='Evidence Rating'
            options={[
              { value: '', title: '-- Choose a rating --', disabled: true },
              { value: 'Strong', title: 'Strong' },
              { value: 'Good', title: 'Good' },
              {
                value: 'Emerging',
                title: 'Emerging',
              },
              { value: 'Limited', title: 'Limited' },
            ]}
            value={params.evidence_rating ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                evidence_rating: e.target.value,
              }))
            }
          />

          <TextInput
            type='number'
            title='Age Low'
            placeholder='Age Low'
            value={params.age_low}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                age_low: e.target.value,
              }))
            }
          />

          <TextInput
            type='number'
            title='Age High'
            placeholder='Age High'
            value={params.age_high}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                age_high: e.target.value,
              }))
            }
          />

          <CheckboxInput
            title='Use V2 Design'
            checked={params.v2}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                v2: Number(e.target.checked),
              }))
            }
          />

          <FieldValue title='Created at' value={params.created_at} />

          <FieldValue title='Updated at' value={params.updated_at} />

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Guides</label>
              {params?.guides_lookup?.length > 0 ? (
                <>
                  {params?.guides_lookup.map((condition_guide, index) => {
                    if (condition_guide.deleted) {
                      return null;
                    }
                    return (
                      <div key={index} className={'flex justify-space'}>
                        <Select
                          className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                          classNamePrefix='select'
                          value={guides.find(
                            obj =>
                              parseInt(obj.id) ===
                              parseInt(condition_guide.guide_id),
                          )}
                          isClearable={false}
                          isSearchable={true}
                          name='guides'
                          placeholder='-- Choose a guide --'
                          options={guides}
                          getOptionLabel={option => option.display_title}
                          getOptionValue={option => option.id}
                          onChange={e => {
                            const selectedGuide = parseInt(e.id);
                            const newList = params.guides_lookup.slice();

                            if (!selectedGuides.includes(selectedGuide)) {
                              if (newList[index]) {
                                newList[index].guide_id = selectedGuide;
                              }
                            }
                            setParams(prevState => ({
                              ...prevState,
                              guides_lookup: newList,
                            }));
                          }}
                        />

                        <button
                          type='button'
                          className='flex h-10 w-20 ml-2 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
                          onClick={() => {
                            const newList = params.guides_lookup.slice();

                            if (newList[index]) {
                              if (newList[index].id > 0) {
                                newList[index].deleted = true;
                              } else {
                                newList.splice(index, 1);
                              }
                            }
                            setParams(prevState => ({
                              ...prevState,
                              guides_lookup: newList,
                            }));
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    );
                  })}
                </>
              ) : null}
              <Select
                className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                classNamePrefix='select'
                value={0}
                isClearable={true}
                isSearchable={true}
                name='guides'
                placeholder='-- Choose a guide --'
                options={guides}
                getOptionLabel={option => option.display_title}
                getOptionValue={option => option.id}
                onChange={e => {
                  const selectedGuide = parseInt(e.id);
                  const newList =
                    params?.guides_lookup?.length > 0
                      ? params.guides_lookup.slice()
                      : [];

                  if (!selectedGuides.includes(selectedGuide)) {
                    newList.push({
                      id: -1,
                      condition_id: params.id,
                      guide_id: parseInt(e.id),
                    });
                  }
                  setParams(prevState => ({
                    ...prevState,
                    guides_lookup: newList,
                  }));
                }}
              />
            </div>
          </div>
        </PageDetailCard>
        <PageDetailCard column={1} title={'About'}>
          <MarkdownEditor
            height={300}
            value={params.about}
            onChange={value => {
              setParams(prevState => ({
                ...prevState,
                about: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>

        <PageDetailCard column={1} title={'Decreased Risk'}>
          <MarkdownEditor
            height={300}
            value={params.decreased_risk}
            onChange={value => {
              setParams(prevState => ({
                ...prevState,
                decreased_risk: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
        <PageDetailCard column={1} title={'Increased Risk'}>
          <MarkdownEditor
            height={300}
            value={params.increased_risk}
            onChange={value => {
              setParams(prevState => ({
                ...prevState,
                increased_risk: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
        <PageDetailCard column={1} title={'FAQ'}>
          <MarkdownEditor
            height={300}
            value={params.faq}
            onChange={value => {
              setParams(prevState => ({
                ...prevState,
                faq: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
        <PageDetailCard column={1} title={'References'}>
          <MarkdownEditor
            height={300}
            value={params.references}
            onChange={value => {
              setParams(prevState => ({
                ...prevState,
                references: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}

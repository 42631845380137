import React, { useEffect, useRef } from 'react';
import MDEditor from '@uiw/react-md-editor';
import './MarkdownEditor.css';

export default function MarkdownEditor(props) {
  const mdEditorRef = useRef(null);

  const handleEditorScroll = e => {
    const { target } = e;
    const editorView = target;
    const previewView = mdEditorRef.current?.container?.querySelector(
      '.w-md-editor-preview',
    );
    if (!editorView || !previewView) return;

    const { scrollTop, scrollHeight, clientHeight } = target;
    const editorScrollRatio = scrollTop / (scrollHeight - clientHeight);
    const previewScrollHeight =
      previewView.scrollHeight - previewView.clientHeight;
    previewView.scrollTop = previewScrollHeight * editorScrollRatio;
  };

  useEffect(() => {
    if (!props.autoSyncScroll) {
      return;
    }

    const editorView = mdEditorRef.current?.container?.querySelector(
      '.w-md-editor-text-input',
    );

    if (editorView) {
      editorView.addEventListener('scroll', handleEditorScroll);
    }
    return () => {
      if (editorView) {
        editorView.removeEventListener('scroll', handleEditorScroll);
      }
    };
  }, [mdEditorRef]);

  return (
    <div className='forceTextareaHeight'>
      <MDEditor
        ref={mdEditorRef}
        height={props.height}
        value={props.value}
        onChange={props.onChange}
        highlightEnable={props.highlightEnable}
        preview={props.preview}
        hideToolbar={props.hideToolbar}
        textareaProps={props.textareaProps}
        commands={props.commands}
      />
    </div>
  );
}
